@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.trigger {
  padding: 0 24px;
  font-size: 18px;
  Line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

.logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3)
}

.ant-layout {
  min-height: 100vh;
}

.ant-layout-sider{
  flex: 0 0 250px !important;
  max-width: 250px !important;
  min-width: 250px !important;
  width: 250px !important;
  
}
.logo{
  background-color: #22a4db;
  margin: 0;
  height: 64px;
}
.sm-logo{
  display: none;
}
.ant-layout-sider-collapsed .sm-logo{
  display: block;
}
.ant-layout-sider-collapsed .lg-logo{
  display: none ;
}

.ant-layout-sider-collapsed {
  flex: 0 0 80px !important;
  max-width: 80px !important;
  min-width: 80px !important;
  width: 80px !important;
  
}

main{
  height: 85vh;
  overflow-y: scroll;
  background-color: transparent !important;
}

main::-webkit-scrollbar{
  width: 0px;
}

.ant-menu-title-content{
  font-size: 16px;
}

.ant-menu-dark .ant-menu-item-selected {
  background-color: #ff00f700; /* Set your desired background color */

}

